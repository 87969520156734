<template>
  <div class="container">
    <div class="title">
      <el-image
        :src="require('../../../assets/image/login-tit.png')"
      ></el-image>
    </div>
    <div class="logo-box">
      <h3>登 录</h3>
      <div class="line"></div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-position="left"
      >
        <el-form-item prop="username">
          <el-input
            v-model.trim="ruleForm.username"
            prefix-icon="el-icon-user"
            style="width: 100"
            placeholder="账号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="ruleForm.password"
            prefix-icon="el-icon-lock"
            style="width: 100"
            type="password"
            placeholder="密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-row type="flex" align="middle">
            <el-col
              ><el-input
                v-model="ruleForm.code"
                style="width: 170px; margin-right: 10px"
              ></el-input
            ></el-col>
            <el-col
              ><div style="height: 40px" @click="refreshCode()">
                <Identify :identifyCode="identifyCode" /></div
            ></el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <!-- <p class="warn-msg">{{ errMsg }}</p> -->
      <el-button
        type="primary"
        @click="submitForm"
        style="width: 100"
        :loading="loading"
        >登&ensp;&ensp;&ensp;&ensp;录</el-button
      >
      <el-row
        type="flex"
        justify="space-between"
        align="middle"
        style="margin: 10px 0"
      >
        <el-col :span="9">
          <!-- <el-checkbox v-model="checked" @change="onChange"></el-checkbox>
          <span class="remeber">记住用户名和密码</span> -->
        </el-col>
        <el-col :span="4">
          <router-link to="/forgetPwd">
            <el-link type="info" @click="clearCookie">忘记密码</el-link>
          </router-link>
        </el-col>
      </el-row>
    </div>
    <div class="footer">
      <div class="footer-content">
        <div class="footer-center">
          <p>版权所有：秦皇岛市教育局</p>
          <p>地址：河北省秦皇岛市海港区秦皇东大街485号</p>
					<p><a class="beian" href="https://beian.miit.gov.cn" target="_blank">冀ICP备05030247号-7</a></p>
        </div>
        <div class="footer-center">
          <!-- <p>违法和不良信息举报电话：0311-86036653</p>
          <p>E-mail：qhdedu@yeah.net</p> -->
          <p>技术支持：师达教育</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Identify from "@/components/Identify";
export default {
  data() {
    var validPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("密码不能为空"));
      } else {
        // let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,12}$/;
        let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8}$/
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("密码为8位大小写字母和数字组合"));
        }
      }
    };
    var validCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("图形验证码不能为空"));
      } else {
        if (this.identifyCode == value) {
          callback();
        } else {
          this.refreshCode();
          return callback(new Error("图形验证码有误"));
        }
      }
    };
    return {
      errMsg: "",
      checked: false,
      loading: false,
      ruleForm: {
        // username: "15366251523", //老师
        // username: 'G13526483548965431', //学生
        // password: "123456",
        username: "",
        password: "",
        code: ''
      },
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          // { required: true, validator: validPass, trigger: "blur" },
        ],
        code: [{ required: true, trigger: "blur", validator: validCode }],
      },
      identifyCode: "",
      identifyCodes: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
    };
  },
  components: { Identify },
  created() {
    var that = this
    document.onkeydown = function (e) {
      var key = window.event.keyCode
      if (key === 13) {
        that.submitForm() // 触发事件
      }
    }
  },
  destroyed() {
  	document.onkeydown = () => {}
  },
  mounted() {
    this.getCookie();
    this.refreshCode()
  },
  methods: {
    async submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.checked) {
            this.clearCookie();
            this.setCookie(this.ruleForm.username, this.ruleForm.password, 7);
          }
          this.loading = true;
          this.$store
            .dispatch("user/login", this.ruleForm)
            .then(() => {
              this.loading = false;
              this.$store.dispatch("user/getUserInfo").then((res) => {
				let role = this.$store.getters.roles[0].roleKey
                if (role == "parents") {
                  let data = res.data;
                  let parents = {
                    loginName: res.data.loginName,
                    clientId: res.data.clientId,
                  }
                  sessionStorage.setItem('parents',JSON.stringify(parents))
                  let username = JSON.parse(data.studentId)[0].loginName;
                  let password = JSON.parse(data.studentId)[0].password;
                  this.$store.dispatch("user/login", { username, password }).then(res=> {
                    this.$store.dispatch("user/getUserInfo", parents).then(() =>{
                      this.$router.push("/");
                    })
                  });
                } else if (role == 'ban_wei') {
					this.$router.push("/bwDynamic/personal")
				} else if (role == 'xin_li_plan') {
					this.$router.push("/ptTeachPlan/planDetail")
				} else {
                  this.$router.push("/");
                }
              });
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.refreshCode()
          return false;
        }
      });
    },
    onChange(val) {
      // console.log(val);
    },
    // 设置cookie
    setCookie(c_name, c_pwd, exdays) {
      var exdate = new Date();
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存天数
      // 字符串拼接cookie
      window.document.cookie =
        "userName" + "=" + c_name + ";expires=" + exdate.toGMTString();
      window.document.cookie =
        "password" + "=" + c_pwd + ";expires=" + exdate.toGMTString();
    },
    // 读取cookie
    getCookie() {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split("; ");
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("=");
          if (arr2[0] == "userName") {
            this.ruleForm.username = arr2[1];
          } else if (arr2[0] == "password") {
            this.ruleForm.password = arr2[1];
          }
        }
      }
    },
    // 清除cookie
    clearCookie() {
      this.setCookie("", "", -1);
    },
    // 生成随机数
    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 更新验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
      // console.log("当前验证码:", this.identifyCode);
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      // console.log('data, len:', data, len)
      for (let i = 0; i < len; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length - 1)];
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.container {
  width: 100%;
  height: 100vh;
  position: relative;
  padding-top: 50px;
  box-sizing: border-box;
  background: url("../../../assets/image/login.jpg") no-repeat center / 100%
    100%;
  .title {
    font-size: 42px;
    font-weight: bolder;
    color: #fff;
    margin-top: 42px;
    text-align: center;
    text-shadow: 0px 4px 6px rgba(0, 5, 121, 0.26);
  }
  .logo-box {
    width: 400px;
    padding: 20px 40px;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    h3 {
      color: #305893;
      text-align: center;
      margin-top: 0;
    }
    .line {
      border-bottom: 1px solid #e2e3e7;
      margin-bottom: 22px;
    }
    .warn-msg {
      height: 16px;
      font-size: 12px;
      color: red;
    }
    .remeber {
      font-size: 12px;
      margin-left: 5px;
    }
    .el-button {
      width: 100%;
    }

    .el-link {
      font-size: 12px;
    }
  }
  .footer {
    width: 100%;
    height: 96px;
    background: rgba($color: #1c588b, $alpha: 0.92);
    position: absolute;
    bottom: 0;
    .footer-content {
      max-width: 1400px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .footer-center {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          color: #ffffff;
          display: inline-block;
          margin: 0 15px;
          opacity: 0.5;
		  a {
			  color: #FFF;
			  text-decoration: underline;
		  }
        }
      }
    }
  }
}
</style>